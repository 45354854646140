@use 'responsive';

// LANDSCAPE

@media (orientation: landscape) {
	// General
	main {
		position: fixed;
		bottom: 0;
		// margin-top: 5vh;

		// smartphone first
		width: 100%;
		// height: 92%;
		margin-bottom: 8%;

		// justify-content: space-between;
	}

	// Title
	header {
		order: 2;

		// font-size: min(19vw, 3rem);
		font-size: calc(2rem + 4vw);
	}

	$titleMargin: 1.2rem;

	header > span {
		&:first-child {
			margin-right: $titleMargin;
		}
		&:last-child {
			margin-left: $titleMargin;
		}
	}

	// Nav
	nav {
		order: 1;
		// font-size: min(8vw, 2.4rem);
		font-size: calc(2rem + 2vw);
		text-align: center;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;

		$navMargin: 1.5rem;

		// margin: -$navMargin 0;
		margin: $navMargin 0;
		& > * {
			margin: 0 $navMargin;
		}
	}

	nav[arrow] {
		font-size: calc(2rem + 2vw);
	}

	// Content
	section[content] {
		max-width: 70%;
		// max-height: 65%;
		// // min-height: 60%;

		// font-size: 3.5vh;
		overflow: auto;
	}

	// ABOUT
	section[about] {
		max-height: 80%;
		// max-width: 40%;
		// width: 40%;
		font-size: 3vh;
		-webkit-overflow-scrolling: touch;
		text-align: center;
	}

	div[names] {
		font-size: 4.4vh;

		& > p:first-child {
			margin-right: 22vw;
		}
	}

	// RELEASE
	section[release] {
		font-size: 4.5vh;
	}

	div[ep-cover] {
		width: 45vh !important;
		height: 45vh !important;
	}

	// LISTEN
	section[listen] {
		width: 75%;
		margin-bottom: -1%;
	}

	div[background] {
		background-position: center center;
		transition: background-image 0.1s ease-out, background-position 0.0s ease-out;
		&[release] {
			filter: saturate(0.4);
		}

		@include responsive.w_3840 {
			background-image: url(../media/cover-pulse-2023.jpg);
		}
		@include responsive.w_3000 {
			background-image: url(../media/cover-pulse-2023.jpg);
		}
		@include responsive.w_1920 {
			background-image: url(../media/cover-pulse-2023.jpg);
		}
		@include responsive.w_1536 {
			background-image: url(../media/cover-pulse-2023.jpg);
		}

		&[about] {
			@include responsive.w_3840 {
				background-image: url(../media/backg04.jpg);
			}
			@include responsive.w_3000 {
				background-image: url(../media/backg04.jpg);
			}
			@include responsive.w_1920 {
				background-image: url(../media/backg04.jpg);
			}
			@include responsive.w_1536 {
				background-image: url(../media/backg04.jpg);
			}
		}
		&[listen] {
			@include responsive.w_3840 {
				background-image: url(../media/backg03_f_w_3840.jpg);
			}
			@include responsive.w_3000 {
				background-image: url(../media/backg03_f_w_3000.jpg);
			}
			@include responsive.w_1920 {
				background-image: url(../media/backg03_f_w_1920.jpg);
			}
			@include responsive.w_1536 {
				background-image: url(../media/backg03_f_w_1536.jpg);
			}
		}
		&[release] {
			@include responsive.w_3840 {
				// background-image: url(../media/apart3_w_1200.jpg) !important;
			}
			@include responsive.w_3000 {
				// background-image: url(../media/apart3_w_1200.jpg) !important;
			}
			@include responsive.w_1920 {
				// background-image: url(../media/apart3_w_800.jpg) !important;
			}
			@include responsive.w_1536 {
				// background-image: url(../media/apart3_w_400.jpg) !important;
			}
		}
	}
}
