@use "colors";
@use "landscape";
@use "portrait";
@use "responsive";

@font-face {
  font-family: "Kanit";
  src: url("../media/Kanit-Regular.ttf");
}
@font-face {
  font-family: "Kanit";
  src: url("../media/Kanit-Bold.ttf");
  font-weight: bold;
}

html {
  height: 100%;
  color: colors.$main;
  overflow: hidden;
}

a {
  cursor: pointer;
}

*[link] {
  &:hover,
  &:active,
  &:focus {
    filter: invert(1);
  }
}

*[boxed] {
  background-color: rgba($color: #000000, $alpha: 0.84);
}

*[hidden] {
  display: none !important;
}

*[transparent] {
  opacity: 0;
}

*[inverted] {
  filter: invert(1);
}

p {
  display: inline;
}

div[background] {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;

  background-size: cover;
  background-color: black;
  background-attachment: fixed;
  background-repeat: no-repeat;
  // background-origin: content-box;
  transition: background-image 0.2s ease-out;

  // filter: saturate(0.9);
  &[release] {
    transition: background-image 0.1s ease-out,
      background-position 0.1s ease-out;
    filter: brightness(0.3) saturate(0.3);
  }
}

main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  // font-weight: 400;
  font-family: "Courier New", Courier, monospace;
}

header {
  font-family: Kanit, sans-serif;
  font-weight: bold;
  line-height: 0.75;
  text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black,
    2px 2px 0 black;

  // IMPRECISE
  // margin-top: 3%;

  & > span {
    // removes animation jiggle bug
    // display: inline-block;

    &[amp] {
      font-weight: 400;
    }
  }
}

nav {
}

section[content] {
  // display: none;
}

section[about] {
}

div[names] {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  font-weight: 600;

  & > p {
    margin: 5vmax 0 0 0;
  }
}

section[release] {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  & > p {
    margin: 1rem 0 0 0;
  }
}

div[ep-cover] {
  width: 40vh;
  height: 40vh;
  // z-index: -1;
  // flex: 1;
  // margin-bottom: 0.5rem;
  border: 0.2rem solid colors.$main;

  background-size: cover;
  background-color: black;
  background-position: center 0vh;
  background-repeat: no-repeat;

  cursor: pointer;

  @include responsive.h_2220 {
    background-image: url(../media/cover-pulse-2023.jpg);
  }
  @include responsive.h_1536 {
    background-image: url(../media/cover-pulse-2023.jpg);
  }
  @include responsive.h_800 {
    background-image: url(../media/cover-pulse-2023.jpg);
  }
}

iframe:first-child {
  width: 100%;
  height: 40vh;
}

iframe:last-child {
  width: 100%;
  height: 20vh;
  filter: invert(1) hue-rotate(180deg) saturate(1.5) contrast(1.5);
}
